<template>
  <div class="mb-3 card transparent">

    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="list">
          {{ $t('erp.lang_nav_vouchers') }}
        </v-tab>
        <v-tab key="listOld">
          {{ $t('erp.lang_oldVoucherList') }}
        </v-tab>
        <v-tab key="add">{{ $t('erp.lang_issue_voucher') }}</v-tab>

        <v-tabs-items touchless v-model="tab">
          <v-tab-item key="list">

            <!-- Table -->

            <Datatable v-if="showTable" ref="voucherDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOUCHER"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="GiftCards"
                       @displayEntry="displayEntry"
                       show-display-buttons
            >
              <template v-slot:item.value="{item}">
                {{ item.value | currency }}
              </template>
            </Datatable>

            <v-container v-if="showUpdate" fluid>
              <v-layout>
                <v-flex md12>
                  <!-- Table: Click -->
                  <div>
                    <v-layout>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="voucherBalance"
                                      :label="$t('erp.lang_VoucherValue')"
                                      autocomplete="off"
                                      disabled
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="issuingDate"
                                      :label="$t('erp.lang_voucherIssuerID')"
                                      autocomplete="off"
                                      disabled
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="lastUse"
                                      :label="$t('generic.lang_lastchange')"
                                      autocomplete="off"
                                      disabled
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex class="text-right">
                        <v-btn text color="error" @click="resetData">{{
                            $t('generic.lang_prev')
                          }}
                        </v-btn>
                        <v-btn color="success"
                               v-if="this.$store.getters['permissions/checkPermission']('reprintVouchers')"
                               :disabled="loadingPrinting" :loading="loadingPrinting" @click="reprintVoucherPDF(id)">{{
                            $t('erp.lang_reprintVoucherPDF')
                          }}
                        </v-btn>
                        <v-btn color="success"
                               v-if="this.$store.getters['permissions/checkPermission']('reprintVouchers')"
                               :disabled="loadingPrinting" :loading="loadingPrinting" @click="reprintVoucher(id,voucherBalance)">{{
                            $t('erp.lang_reprintVoucher')
                          }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item key="listOld">

            <!-- Table -->

            <Datatable v-if="showTable" ref="oldVoucherDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.VOUCHEROLD"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="GiftCards"
            />

            <v-container v-if="showUpdate" fluid>
              <v-layout>
                <v-flex md12>
                  <!-- Table: Click -->
                  <div>
                    <v-layout>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="voucherBalance"
                                      :label="$t('erp.lang_VoucherValue')"
                                      autocomplete="off"
                                      disabled
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="issuingDate"
                                      :label="$t('erp.lang_voucherIssuerID')"
                                      autocomplete="off"
                                      disabled
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 mr-2 ml-2>
                        <v-text-field outlined
                                      @focus="showTouchKeyboard"
                                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      v-model="lastUse"
                                      :label="$t('generic.lang_lastchange')"
                                      autocomplete="off"
                                      disabled
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex class="text-right">
                        <v-btn text color="error" @click="resetData">{{
                            $t('generic.lang_prev')
                          }}
                        </v-btn>
                        <v-btn color="success"
                               v-if="this.$store.getters['permissions/checkPermission']('reprintVouchers')"
                               :disabled="loadingPrinting" :loading="loadingPrinting" @click="reprintVoucher(id,voucherBalance)">{{
                            $t('erp.lang_reprintVoucher')
                          }}
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-layout>
                <v-flex md6 mr-2 ml-2>
                  <v-text-field outlined
                                @focus="showTouchKeyboard"
                                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                v-model="voucherBalanceAdd"
                                :label="$t('erp.lang_VoucherValue')"
                                autocomplete="off"
                                required
                  ></v-text-field>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                  <v-autocomplete outlined
                                  v-model="customerIdAdd"
                                  :loading="loading"
                                  :items="items"
                                  :search-input.sync="addSearch"
                                  cache-items
                                  class="mx-3"
                                  flat
                                  hide-no-data
                                  hide-details
                                  :label="$t('customers.lang_nav_searchcust')"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn :loading="this.add_loader" color="success"
                         :disabled="voucherBalanceAdd.length < 1 || this.add_loader"
                         @click="addData(false)">{{
                      $t('generic.lang_add')
                    }}
                  </v-btn>
                  <v-btn :loading="this.add_loader" color="success"
                         :disabled="voucherBalanceAdd.length < 1 || this.add_loader"
                         @click="addData(true)">{{
                      $t('accounting.lang_andPrint')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="300">
      <v-card>
        <v-card-title>Info</v-card-title>
        <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="errorDialogMessage = ''">{{ $t('generic.lang_understand') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from '../../../config'
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import {createVoucherPrintingData} from "../../../plugins/printing/printVoucher";
import {mapState} from "vuex";
import FileSaver from 'file-saver';
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
  components: {
    Datatable
  },

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      add_loader: false,
      datatableHeaders: [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id"
        },
        {text: this.$t('erp.lang_voucherIssuingDate'), value: "issueDate", sort: "desc"},
        {text: this.$t('erp.lang_voucherIssuerID'), value: "issueID"},
        {text: this.$t('erp.lang_VoucherValue'), value: "value"},
        {text: this.$t('customers.lang_cust_prename'), value: "prename"},
        {text: this.$t('customers.lang_cust_name'), value: "lastname"},
        {text: this.$t('customers.lang_customerID'), value: "custID"},
        {text: this.$t('generic.lang_lastchange'), value: "lastChange"}
      ],
      excelColumns: [
        {label: this.$t('generic.lang_id'), field: "id"},
        {label: this.$t('erp.lang_voucherIssuingDate'), field: "issueDate"},
        {label: this.$t('erp.lang_voucherIssuerID'), field: "issueID"},
        {label: this.$t('erp.lang_VoucherValue'), field: "value"},
        {label: this.$t('customers.lang_cust_prename'), field: "prename"},
        {label: this.$t('customers.lang_cust_name'), field: "lastname"},
        {label: this.$t('customers.lang_customerID'), field: "custID"},
        {label: this.$t('generic.lang_lastchange'), field: "lastChange"}
      ],
      id: null,
      voucherBalance: "",
      issuingDate: null,
      lastUse: null,
      voucherBalanceAdd: "",
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      voucherData: [],
      dataTable: null,
      loadingPrinting: false,
      errorDialogMessage: ""
    }
  },

  watch: {
    addSearch(val) {
      val && val !== this.select && this.querySelections(val)
    },
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },

  computed: {
    ...mapState([
      'api'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  methods: {
    displayEntry(entry) {
      let self = this;
      let id = entry.id;

      self.axios.post(ENDPOINTS.ERP.VOUCHER.GET, {
        voucherUUID: id
      }).then((res) => {
        if (res.status === 200) {
          self.id = id;
          self.voucherBalance = res.data.formfillData.textFields.voucherBalance;
          self.issuingDate = res.data.formfillData.textFields.issuingDate;
          self.lastUse = res.data.formfillData.textFields.lastUse;
          self.showTable = false;
          self.showUpdate = true;
        } else {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    addData(can_print = false) {

      let self = this;
      this.add_loader = true;
      this.axios.post(ENDPOINTS.ERP.VOUCHER.CREATE, {
        voucherBalance: this.voucherBalanceAdd,
        customerID2: this.customerIdAdd
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_voucher_added'),
            color: "success"
          });


          self.voucherAdd = "";
          self.showList();

          self.$refs.voucherDatatable.getDataFromApi();
          self.$refs.voucherDatatable.resetSelectedRows();

          if (can_print && res.data.voucherId) {
            this.reprintVoucher(res.data.voucherId, res.data.voucherBalance);
          }
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.add_loader = false;
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },
    updateData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.VOUCHER.UPDATE, {
        editVoucherID: this.id,
        voucherName: this.text
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          self.resetData();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    querySelections: function (searchText) {
      let self = this;
      self.loading = true;
      self.axios.get(ENDPOINTS.ERP.CUSTOMER.GET + "?search=" + searchText)
          .then((res) => {
            let items = res.data.results;
            for (let i = 0; i < items.length; i++) {
              let item = items[i];
              self.items.push({text: item.text, value: item.id});
            }
            self.loading = false;
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    reprintVoucher(id = this.id, balance = this.voucherBalance) {
      // CHECK IF PRINTER EXIST
      if(!this.invoicePrinter){
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_noPrinterSelected'),
          color: "warning"
        });
        return;
      }
      let printVouchersData = createVoucherPrintingData("V$" + id, balance);

      this.loadingPrinting = true;

      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter,printVouchersData).then(()=>{

      }).catch((err)=>{
        this.errorDialogMessage = this.$t('erp.lang_voucherCouldNotBeReprinted');
      }).finally(()=>{
        this.loadingPrinting = false;
      })

    },
    reprintVoucherPDF(id = this.id) {


      this.loadingPrinting = true;

        this.axios.post(ENDPOINTS.ERP.VOUCHER.GETPDF, {
          voucherId: id,
        },{
        responseType: 'arraybuffer',
      }).then((res) => {
          this.loadingPrinting = false;

          if (res.status === 200) {
            FileSaver.saveAs(new Blob([res.data], {
              type: "application/pdf"
            }), "Gutschein_"+id+".pdf");

            this.loadingPrinting = false;
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });
          }
        }).catch((e) => {
          console.log(e);
          this.errorDialogMessage = this.$t('erp.lang_voucherCouldNotBeReprinted');
          this.loadingPrinting = false;
        });


    },

  },
}
</script>
